<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-03-29 18:44:51
 * @LastEditTime: 2023-03-02 14:23:49
 * @LastEditors: cyy 1346967714@qq.com
 * @Description: 推广员》推广员管理》详情
 * @FilePath: \teacherdingding.com\src\views\courseWorkbook\components\studentWorkbook.vue
-->
<template>
  <div id="studentWorkbook">
    <div class="basic_data">
      <el-descriptions :column="1">
        <el-descriptions-item label="作业">
          {{ themeHader.name }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions>
        <el-descriptions-item label="学员作业隐私">
          {{
            themeHader.privacy_type == 1
              ? '提交后可查看'
              : themeHader.privacy_type == 2
              ? '未提交可查看'
              : '不可查看'
          }}
        </el-descriptions-item>
        <el-descriptions-item label="提交截止时间">
          <template v-if="themeHader.start_submit_time > 0">
            {{
              themeHader.start_submit_time
                | formatTimeStamp('yyyy-MM-dd hh:mm:ss')
            }}

            -
            {{
              themeHader.end_submit_time
                | formatTimeStamp('yyyy-MM-dd hh:mm:ss')
            }}
          </template>
          <template v-else>无</template>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="flex-center student-work-data">
      <div class="item">
        <div class="title">提交情况：</div>
        <div class="flex-center data">
          <div class="block">
            <div class="count black">{{ themeHader.all_num }}</div>
            <div class="text">学员总数</div>
          </div>
          <div class="block">
            <div class="count green">{{ themeHader.sub_user_num }}</div>
            <div class="text">已提交(人)</div>
          </div>
          <div class="block">
            <div class="count orange">{{ themeHader.sub_num }}</div>
            <div class="text">未提交(人)</div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="title">点评情况：</div>
        <div class="flex-center data">
          <div class="block">
            <div class="count black">{{ themeHader.sub_user_num }}</div>
            <div class="text">已提交(人)</div>
          </div>
          <div class="block">
            <div class="count green">{{ themeHader.comment_num }}</div>
            <div class="text">已点评(人)</div>
          </div>
          <div class="block">
            <div class="count orange">{{ themeHader.no_comment_num }}</div>
            <div class="text">未点评(人)</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main_menu">
      <el-tabs v-model="post.type" @tab-click="tabClick">
        <el-tab-pane
          :label="
            `全部(${Number(themeHader.comment_num) +
              themeHader.no_comment_num})`
          "
          name="0"
        ></el-tab-pane>
        <el-tab-pane
          :label="`已点评(${themeHader.comment_num})`"
          name="2"
        ></el-tab-pane>
        <el-tab-pane
          :label="`未点评(${themeHader.no_comment_num})`"
          name="1"
        ></el-tab-pane>
      </el-tabs>
      <div class="search-input-box mb20 mt10 mr20">
        <el-input
          v-model="post.search"
          placeholder="输入学员名称搜索"
        ></el-input>
      </div>
      <!--客户关系-->
      <pagination2
        ref="list"
        :option="post"
        url="/Homework/commentUserList"
        class="pl20 pr20"
        @complete="complete"
      >
        <template v-slot:default="{ tableData }">
          <el-table
            ref="tablelist"
            :data="tableData"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
            @selection-change="handleSelectionChange"
            @select-all="selectAll"
          >
            <el-table-column type="selection" width="42"></el-table-column>
            <el-table-column label="学员">
              <template slot-scope="{ row }">
                <div class="flex-center">
                  <img class="mr15" :src="row.uphoto" width="28" alt="" />
                  {{ row.uname }}
                </div>
              </template>
            </el-table-column>

            <el-table-column label="提交时间">
              <template slot-scope="{ row }">
                {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
              </template>
            </el-table-column>
            <el-table-column label="点评时间">
              <template slot-scope="{ row }">
                <template v-if="row.comment_time > 0">
                  {{
                    row.comment_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss')
                  }}
                </template>
                <template v-else>--</template>
              </template>
            </el-table-column>

            <el-table-column label="点评老师" prop="teacher_name">
              <template slot-scope="{ row }">
                <template v-if="row.comment_time > 0">
                  {{ row.teacher_name }}
                </template>
                <template v-else>--</template>
              </template>
            </el-table-column>

            <el-table-column label="作业状态">
              <template slot-scope="{ row }">
                {{ row.is_show == 1 ? '显示' : '隐藏' }}
              </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right">
              <template slot-scope="{ row }">
                <el-button
                  type="text"
                  @click="
                    $router.push({
                      path: `/courseWorkbook/studentWorkbook/${homework_theme_id}/answer/${row.user_id}`,
                    })
                  "
                >
                  查看
                </el-button>
                <el-divider direction="vertical"></el-divider>
                <el-button
                  type="text"
                  @click="hide(row)"
                  v-if="row.is_show == 1"
                >
                  隐藏
                </el-button>
                <el-button type="text" @click="show(row)" v-else>
                  显示
                </el-button>
                <el-divider direction="vertical"></el-divider>
                <el-button type="text" @click="delBookUser([row.user_id])">
                  删除重做
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>

        <template v-slot:footer>
          <div class="pt20 pb20">
            <el-checkbox v-model="checked" @change="selectAll2"></el-checkbox>
            <el-button
              class="ml20"
              type=""
              @click="batchReviews"
              :disabled="selected.length == 0"
              size="medium"
            >
              批量点评
            </el-button>
            <el-button
              class="ml20"
              type=""
              size="medium"
              :disabled="selected.length == 0"
              @click="show()"
            >
              批量显示
            </el-button>
            <el-button
              type=""
              size="medium"
              :disabled="selected.length == 0"
              @click="hide()"
            >
              批量隐藏
            </el-button>
            <el-button
              type=""
              size="medium"
              :disabled="selected.length == 0"
              @click="delBookUser(selected.map(item => item.user_id))"
            >
              批量删除重做
            </el-button>
          </div>
        </template>
      </pagination2>
    </div>
    <!---批量点评--->
    <transition name="fade">
      <div v-if="visible" class="mask"></div>
    </transition>
    <el-dialog
      width="800px"
      :title="`批量点评，已选${selected.length}位学员`"
      :visible="visible"
      :close-on-click-modal="false"
      @close="visible = false"
      :modal="false"
    >
      <memberworkbook
        v-if="visible"
        :batchReviews="selectedIds"
        @close="commentClose"
      ></memberworkbook>
    </el-dialog>
  </div>
</template>

<script>
import bg from '@/mixin/background'
import memberworkbook from '@/views/courseWorkbook/components/memberworkbook'

export default {
  name: 'studentWorkbook',

  components: {
    memberworkbook,
  },

  mixins: [bg],

  data() {
    return {
      themeHader: {},

      post: {
        status: 0,
        type: '0',
        search: '',
        homework_theme_id: this.homework_theme_id,
      },

      selected: [],
      checked: false,
      visible: false,
    }
  },

  filters: {},

  created() {
    this.themeHaderData()
    this.post.homework_theme_id = this.homework_theme_id
  },

  computed: {
    homework_theme_id() {
      return this.$route.params.id
    },

    selectedIds() {
      return this.selected.map(item => item.user_id)
    },
  },

  methods: {
    // 删除重做
    async delBookUser(arr) {
      this.$confirm('是否确定删除重做?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/Homework/delBookUser',
            data: {
              homework_theme_id: this.homework_theme_id,
              user_ids: arr,
            },
            successMsg: true,
          })
          this.tabClick()
          this.$refs.list.reset()
        })
        .catch(() => {})
    },

    tabClick() {
      this.checked = false
    },

    complete(val) {
      this.count = val.count
    },

    // 获取基础数据
    async themeHaderData() {
      const { data } = await this.$http({
        url: '/Homework/themeHader',
        data: { homework_theme_id: this.homework_theme_id },
      })
      this.themeHader = data
    },

    // 全选状态
    selectAll(val) {
      if (val.length == 0) {
        this.checked = false
      } else {
        this.checked = true
      }
    },

    // 列表下边的多选
    selectAll2(val) {
      if (val) {
        this.$refs.tablelist.toggleAllSelection()
      } else {
        this.$refs.tablelist.clearSelection()
      }
    },

    handleSelectionChange(val) {
      this.selected = val
      this.checked = val.length == this.count
    },

    // 批量点评
    batchReviews() {
      this.visible = true
    },

    // 批量显示
    async show(row) {
      await this.$http({
        url: '/homework/setShow',
        data: {
          homework_result_ids: row
            ? [row.homework_result_id]
            : this.selected.map(item => item.homework_result_id),
          is_show: 1,
        },
      })
      this.checked = false
      this.$refs.list.reset()
    },

    commentClose() {
      this.visible = false
      this.$refs.list.reset()
    },

    // 批量隐藏
    async hide(row) {
      await this.$http({
        url: '/homework/setShow',
        data: {
          homework_result_ids: row
            ? [row.homework_result_id]
            : this.selected.map(item => item.homework_result_id),
          is_show: 2,
        },
      })
      this.checked = false
      this.$refs.list.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
#studentWorkbook {
  padding: 20px;
  .basic_data {
    padding: 20px;
    background: #fff;
    ::v-deep .el-descriptions-item__container {
      white-space: nowrap;
      .el-descriptions-item__content {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .student-work-data {
    margin: 20px 0;
    .item {
      flex: 1;
      padding: 20px;
      margin-right: 20px;
      background-color: #fff;
      &:nth-child(2n) {
        margin-right: 0;
      }
      .title {
        font-size: 12px;
        color: #333333;
        margin-bottom: 20px;
      }
      .data {
        &:nth-child(2n) {
          margin: 0 70px;
        }
        .block {
          justify-content: center;
          .count {
            text-align: center;
            font-size: 26px;
            color: #db6e3a;
            margin-bottom: 8px;
            &.black {
              color: #333333;
            }
            &.green {
              color: #3d996f;
            }
            &.orange {
              color: #db6e3a;
            }
          }
          .text {
            font-size: 12px;
            color: #333333;
            text-align: center;
          }
        }
      }
    }
  }
  .main_menu {
    background: #fff;
    ::v-deep .el-tabs__item {
      padding: 0 20px;
    }
  }
  .search-input-box {
    text-align: right;
    float: right;
  }
}
</style>
